.controls {
  display: flex;
}

.controls > * + * {
  margin-left: 1rem !important;
}

.filterPopupContainer {
  margin: 1rem;
}

.filterColumn {
  display: flex;
  flex-direction: column;
}

.filterColumn > * + * {
  margin-top: 1rem;
}

.floatingLabel {
  top: 0.125rem !important;
}
