.container {
  margin-bottom: 4rem !important;
}

.container > :last-child {
  margin-bottom: 0;
}

.titleContainer {
  scroll-margin-top: calc(64px + 3rem + 1px);
}

.titleContainer > h2 {
  margin-bottom: 2rem !important;
}
