.checkboxList {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-inline-start: 0;
  margin-bottom: 0;
}

.checkboxList li {
  margin-bottom: 1rem;
}

/* Emulate the Semantic UI error message tooltip with pointer */
.errorMessage {
  color: var(--theme-error-message-text-color) !important;
  border: 1px solid var(--theme-error-message-border-color) !important;
}
