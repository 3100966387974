.eventLocationName > input {
  text-overflow: ellipsis;
  width: 100% !important;
}

.addressGrid {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0.5rem;
}

.addressGrid > div {
  margin-bottom: 0 !important;
}

.addressGrid > div > label {
  margin-top: 0 !important;
}

.addressGrid > div > div > input {
  width: 100% !important;
}
