.row {
  display: flex;
}

.row > * + * {
  margin-left: 1rem !important;
}

.error {
  font-weight: bold;
  color: red;
  margin-bottom: 0.5em;
}
