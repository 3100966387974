.nameBadge {
  --name-tag-border: 1px dashed #aaa;

  width: calc(4in - 1px);
  height: calc(2.5in - 1px);
  border-top: var(--name-tag-border);
  border-left: var(--name-tag-border);
  border-right: var(--name-tag-border);
  color: black;

  display: inline-block;
  page-break-inside: avoid;

  font-family: Arial, sans-serif;

  /*
     * Alignment hack
     * Without this, when text wrap occurs the top of the cell gets push down for some reason...
     */
  float: left;
}

.nameBadge > .content {
  height: 100%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.nameBadge > .content > .name {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}

.nameBadge > .content > .jobTitle {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.nameBadge > .content > .department {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.nameBadge:nth-child(2n) {
  width: calc(4in - 2px);
  border-left: 0;
}

@media print {
  .nameBadge:nth-child(8n - 1),
  .nameBadge:nth-last-child(2) {
    height: calc(2.5in - 2px);
    border-bottom: var(--name-tag-border);
  }

  .nameBadge:nth-child(8n),
  .nameBadge:last-child {
    border-bottom: var(--name-tag-border);
    page-break-after: always;
  }
}
