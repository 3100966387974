.table {
  margin-top: 0 !important;
}

.topControls {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: end;
  gap: 1rem;
  padding: 1rem 0;
}

.controls {
  display: flex;
}

.controls > * + * {
  margin-left: 1rem !important;
}

.topControls.sticky {
  background-color: var(--theme-base-raised);
  z-index: 1;
  position: sticky;
  /*top: 0;*/
  /*border-bottom: 1px solid var(--theme-base-border-color);*/
}

.selectAllCheckbox {
  /* default semantic-ui td padding + border width */
  margin-left: calc(0.78571429em + 1px);
}

.searchInput {
  min-width: 40ch;
}

.bottomControls {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.optionCell {
  padding: 0 !important;
}

.rowControls button i {
  visibility: hidden;
}

.table tbody > tr:hover > td .rowControls button i,
.table tbody > tr:focus-within > td .rowControls button i {
  visibility: visible;
}
