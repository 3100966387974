.grid {
  min-width: 30ch;
}

.grid > * {
  margin-bottom: 0 !important;
}

.number {
  margin-top: 3.5rem;
}
