.fileButton {
  width: max-content !important;
}

.uploadControls {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.uploadControls > * + * {
  margin-left: 1rem;
}

.errorMessage {
  display: flex;
}

.errorMessage > :first-child {
  flex: 1;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widthMaxContent {
  width: max-content !important;
  min-width: 60ch !important;
  max-width: calc(100vw - 4rem);
  overflow-x: auto;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.controls > * + * {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}
