.bottomGrid {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.bottomGrid > :first-child {
  position: sticky;
  top: calc(64px + 1rem); /* natural height of nav + small margin spacing */
}

.bottomGrid > :last-child {
  flex: 1;
  margin-left: 1rem !important;
}
