.controls {
  display: flex;
  align-items: baseline;
}

.controls > div:first-child {
  flex: 1;
  text-align: left;
}

.controls > div:last-child > button + button {
  margin-left: 0.5rem !important;
}
