.table {
  width: 100%;
}

.limitedWidth {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
