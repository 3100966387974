.mainContainer {
  width: 100%;
}

.topGrid {
  display: flex;
  align-items: baseline;
  padding: 1rem 2rem;
  margin: 0 -2rem;
}

.topGrid > :first-child {
  flex: 1;
}

.bottomGrid {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.bottomGrid > :first-child {
  position: sticky;
  top: calc(64px + 1rem); /* natural height of nav + small margin spacing */
}

.bottomGrid > nav > div {
  width: max-content !important;
}

.bottomGrid > :last-child {
  flex: 1;
  margin-left: 1rem !important;
}

.smallIcon {
  font-size: 1.25rem !important;
}
