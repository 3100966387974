.container label {
  margin-top: 0 !important;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.section > label {
  /* semantic styles */
  font-weight: bold;
  display: block;
  margin: 1rem 0 0.5rem 0 !important;
}
