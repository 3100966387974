.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.full {
  color: var(--theme-base-raised) !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}
