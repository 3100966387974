.container {
  position: relative;
}

.container > img {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
}

.container .controls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.container.disabled .controls {
  display: none;
}

.container .controls > button {
  margin: 0;
}

.container .controls > button + button {
  margin-left: 1rem;
}

.container > .dropzone > * {
  border: 1px dashed var(--theme-base-border-color);
  border-radius: 0.25rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
}

.container.disabled > .dropzone > * {
  border: 1px solid rgba(34, 36, 38, 0.15); /* color taken from semantic */
  padding: 0.5rem 1rem;
  text-align: left;
}
