.root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  margin: 0 0 1em;
  border-radius: 0.28571429rem;
  width: 100%;
}

.editor {
  cursor: text;
  font-size: 16px;
  padding: 1rem;
}

.editor .public-DraftEditorPlaceholder-root,
.editor .public-DraftEditor-content {
  margin: 0 -1rem -1rem;
  padding: 1rem;
}

.editor .public-DraftEditor-content {
  min-height: calc(1rem * 10);
}

.editor div[contenteditable] {
  min-height: 10rem;
}

.hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.editor .blockquote {
  border-left: 0.25rem solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 1rem 0;
  padding: 1rem 2rem;
}

.editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 1rem;
}

.controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 0.25rem;
  user-select: none;
  border-bottom: 1px solid #ddd;
}

.controlsSection {
  display: inline-block;
  padding-top: 2px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-left: 1px solid #ddd;
  min-height: 40px;
}

.controlsSection:last-child {
  border-right: 1px solid #ddd;
}

.fontSizeDropdownControlsSection {
  display: inline-block;
}

.noBorder {
  border: none !important;
}

.activeButtonRoundedBorder {
  border-radius: 4px !important;
}

.fontSizeDropdown {
  color: rgba(0, 0, 0, 0.6) !important;
}
.activeButton {
  color: #5890ff;
}

.additionalControls {
  padding-top: 11px;
}
