.workshopSelected,
.workshopTime {
  width: 1px;
  white-space: nowrap;
}

.workshopName {
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  user-select: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
