.workshop {
  height: 100%;
  padding: 0 0.25rem;
}

.name {
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.facilitator {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.topGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 0.5rem;
}

.menuButton {
  color: var(--theme-primary-text-color) !important;
}

.controls {
  display: inline-flex;
}

.controls > * {
  margin: 0.125rem 0 0 0 !important;
  /*margin-left: 0.25rem !important;*/
}
