/* had to add !important here, otherwise the Semantic UI style wins and sets the color to #e8e8e8 */
.draft {
  background: var(--soft-red) !important;
}

.open {
  background: var(--soft-yellow) !important;
}

.cancelled {
  background: var(--soft-grey) !important;
}

.complete {
  background: var(--soft-green) !important;
}
