.eventMessageError,
.eventMessageError div,
.eventMessageError div:last-child,
.eventMessageError i {
  color: rgb(159, 58, 56);
  border-color: rgb(224, 180, 180);
  background-color: #fff6f6;
}

/*
 * Without this selector, an "extra" border appears on the left-hand side within the editor.
 */
.eventMessageError div[class="DraftEditor-editorContainer"] {
  border: 0;
}
