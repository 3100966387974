.table {
  width: 100%;
}

.noPadding {
  padding: 0 !important;
}

.toggleWrapper {
  margin: 1rem;
}

.toggle {
  margin: 1rem;
}

.name {
  max-width: 40ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
