.editorActionButton {
  border: 0;
  background: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6) !important;
}

.editorActionButton:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}
