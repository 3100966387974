/**
  These styles allow the image to scale when resized and maintain the 3:1 aspect ratio at all times
 */

.CroppedImage-module_container__Y5Nlz {
  width: 100%;
  height: 100%;
}

.CroppedImage-module_image__-yoC0 {
  background-position: center center;
  background-size: cover;
}

.SempraButton-module_sempraButton__BXi5h {
  padding: 0.75em 1.5em;
}

.SempraButton-module_sempraButton__BXi5h {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-text-color);
  transition: background-color 0.3s, border-color 0.3s;
}

.SempraButton-module_sempraButton__BXi5h:hover {
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.SempraButton-module_sempraButton__BXi5h:focus {
  outline: none;
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_outlined__E6yLe {
  background-color: var(--base-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  transition: border-color 0.3s, color 0.3s;
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_outlined__E6yLe:hover {
  border-color: var(--highlight-color);
  color: var(--highlight-color);
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_outlined__E6yLe:focus {
  border-color: var(--highlight-color);
  color: var(--highlight-color);
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_outlined__E6yLe.SempraButton-module_secondary__TBGQw {
  background-color: var(--base-color);
  border: 1px solid var(--default-border-color);
  color: var(--default-text-color);
  transition: border-color 0.3s, color 0.3s;
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_outlined__E6yLe.SempraButton-module_secondary__TBGQw:hover {
  border-color: var(--highlight-color);
  color: var(--highlight-color);
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_outlined__E6yLe.SempraButton-module_secondary__TBGQw:focus {
  border-color: var(--highlight-color);
  color: var(--highlight-color);
}

@keyframes SempraButton-module_glow__zN34Q {
  from {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  to {
    background-color: var(--highlight-color);
    border-color: var(--highlight-color);
  }
}

.SempraButton-module_sempraButton__BXi5h.SempraButton-module_loading__UYaBu,
.SempraButton-module_sempraButton__BXi5h.SempraButton-module_loading__UYaBu:hover,
.SempraButton-module_sempraButton__BXi5h.SempraButton-module_loading__UYaBu:focus {
  animation-duration: 0.5s;
  animation-name: SempraButton-module_glow__zN34Q;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.EventLocation-module_block__-pEaR {
  margin: 1rem 0;
}

.EventLocation-module_additionalDirections__QiqE4 {
  margin-top: 2rem;
}

.Heading-module_heading__2UkB4.Heading-module_highlighted__wOEzo > :first-child {
  color: var(--primary-color);
  font-size: 47px;
  margin-bottom: 2rem;
}

.Heading-module_heading__2UkB4.Heading-module_underlined__QA1CK > :first-child {
  color: var(--base-text-color);
  position: relative;
  padding-bottom: 0.75rem;
  margin-bottom: 1rem;
}

.Heading-module_heading__2UkB4.Heading-module_underlined__QA1CK > :first-child::after {
  content: "";
  display: block;
  position: absolute;
  width: 120px;
  height: 2px;
  background: var(--primary-color);
  bottom: 0;
  left: 0;
}

.Heading-module_heading__2UkB4.Heading-module_underlined__QA1CK > :nth-child(2) {
  color: var(--base-text-color-2);
}

.Heading-module_heading__2UkB4 > h1 {
  font-size: 37px;
}

.Heading-module_heading__2UkB4 > h2 {
  font-size: 26px;
}

.EventRegister-module_block__2vMJt {
  margin: 1rem 0;
}

.EventRegister-module_verticalControls__7Yfii {
  display: flex;
  flex-direction: column;
}

.EventRegister-module_verticalControls__7Yfii > * {
  width: -moz-max-content;
  width: max-content;
}

.EventRegister-module_verticalControls__7Yfii > * + * {
  margin-top: 1em;
}

.EventRegister-module_warning__NZlX4 {
  color: var(--danger-color);
}

.ShareEventButton-module_toastContainer__SJYU5 {
  position: relative;
}

.ShareEventButton-module_toast__35BVb {
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  margin-left: 0.5rem;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.DownloadLink-module_link__t-myM {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  transition: color 0.3s;
}

.DownloadLink-module_link__t-myM:hover {
  text-decoration: none;
  color: var(--highlight-color);
}

.DownloadLink-module_link__t-myM:focus {
  outline: none;
  color: var(--highlight-color);
}

.DownloadLink-module_link__t-myM > * + * {
  margin-left: 1rem;
}

.DownloadLink-module_link__t-myM > .DownloadLink-module_label__-v-Bf {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EventResources-module_list__h3QX1 {
  padding: 0;
}

.EventResources-module_list__h3QX1 > li {
  list-style: none;
  margin-bottom: 1rem;
}

.EventActionsGrid-module_parentContainer__f-FCF {
  background: var(--default-color);
  border-top: 1px solid var(--default-border-color);
  border-bottom: 1px solid var(--default-border-color);
  color: var(--default-text-color);
}

.EventActionsGrid-module_informationRowLayout__VbOxI {
  height: 100%;
}

/* bootstrap xs screen width and above */
.EventActionsGrid-module_informationRowLayout__VbOxI {
  padding: 0;
}
.EventActionsGrid-module_informationColLayout__ovIdi {
  padding: 3rem 0;
}
.EventActionsGrid-module_informationColLayout__ovIdi:not(:last-child) {
  border-bottom: 1px solid var(--default-border-color);
}

/* bootstrap large screen width and above */
@media screen and (min-width: 992px) {
  .EventActionsGrid-module_informationColLayout__ovIdi {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .EventActionsGrid-module_informationColLayout__ovIdi:not(:last-child) {
    border-bottom: none;
    border-right: 1px solid var(--default-border-color);
  }
}

.EventMealsProvided-module_eventMealsProvidedContainer__E8I8l {
  margin-top: 3.5rem;
}

.EventMealsProvided-module_eventMealsProvidedText__csiDk {
  margin-left: 1rem;
}

.Table-module_thead__16tGu {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .Table-module_table__8bnP1 > thead > tr > th,
  .Table-module_table__8bnP1 > tbody > tr > td {
    padding: 0.5rem 0.75rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Table-module_table__8bnP1 > thead > tr > th,
  .Table-module_table__8bnP1 > tbody > tr > td {
    padding: 1rem 1.5rem;
  }
}

.TimeRange-module_time__dDBkJ {
  white-space: nowrap;
}

.TimeRange-module_timeRange__u6ux- > * {
  display: block;
}

@media (min-width: 768px) {
  .TimeRange-module_timeRange__u6ux- > * {
    display: inline;
  }
}

.EventWorkshops-module_heading__ISm7l {
  margin: 2rem 0;
}

.EventWorkshops-module_eventWorkshopTimeColumn__ypkXW {
  min-width: 8.5rem;
}

.EventWorkshops-module_oddRowGroup__wtFXT {
  /* bootstrap style */
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.EventWorkshops-module_evenRowGroup__fLH2f {
  background-color: var(--base-color) !important;
}

.EventWorkshops-module_workshopName__OrKBf {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EventWorkshops-module_collapsedCell__T1yxn {
  width: 1px;
  white-space: nowrap;
}

.EventBody-module_section__3Oddf {
  margin: 3rem 0;
}

.Event-module_actions__dbo7q {
  margin: 4rem 0;
}

.Event-module_heading__rCCv4 {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventList-module_eventButton__RzTe0 {
  width: 100%;
  height: 100%;
}

.EventList-module_alignLeftEventButton__C7kMT {
  width: 100%;
  height: 100%;
  text-align: left;
}

.EventList-module_eventListColumn__R4cb7 {
  margin-top: 3.5rem;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.EventList-module_featuredEventColumn__wjNiO {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.EventList-module_viewMoreButtonContainer__oo9kv {
  margin: 8rem 0 6rem;
}

.EventCard-module_featuredEventCard__tGM3w {
  position: absolute !important;
  bottom: 0;
  border-radius: 0 !important;
  border: 0 !important;
  max-width: 364px;
  width: 364px;
}

.EventCard-module_featuredEventCardImage__oj6sw {
  position: relative;
  background-color: #4a4a4a;
}

/* 812 px seems to be the reasonable "cutoff" for converting from a tile-type layout of three events to rows that show
 one event. */
@media (max-width: 991px) {
  .EventCard-module_featuredEventCard__tGM3w {
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .EventCard-module_eventCardButton__Rs2da,
  .EventCard-module_featuredEventCardButton__Q0KH1 {
    right: 2.5rem !important;
    left: initial !important;
  }
}

.EventCard-module_eventCardContent__QeCYj {
  position: relative;
  /*transition: transform 0.3s;*/
}

.EventCard-module_eventCardContent__QeCYj:hover {
  cursor: pointer;
  /*transform: scale(1.05);*/
}

.EventCard-module_eventCard__QsgVT {
  border-radius: 0 !important;
  border: 0 !important;
}

.EventCard-module_eventCardDateAndTime__wlY9W {
  padding: 0.5rem 0;
}

.EventCard-module_eventCardTitle__CTA86:hover {
  cursor: pointer;
}

.EventCard-module_eventCardBody__VxV3a {
  height: 16rem;
  max-height: 16rem;
  padding: 2.25rem !important;
  background-color: var(--event-card-text-background);
}

/* from here:  http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */
.EventCard-module_eventCardDescription__-M471 {
  overflow: hidden;
  position: relative;
  /* max-height = line-height (1.2) * lines max number (3)
  NOTE:  the line height has been removed to keep the line height in the description consistent with the rest of the
  application. */
  max-height: 6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}

.EventCard-module_eventCardDescription__-M471:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 1rem;
  bottom: 0;
  background: var(--event-card-text-background);
}

.EventCard-module_eventCardDescription__-M471:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 1rem;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: var(--event-card-text-background);
}

.EventCard-module_eventCardButton__Rs2da {
  width: 10rem;
  height: 3rem;
  position: relative;
  bottom: 4.5rem;
  right: 1.5rem;
}

.EventCard-module_registeredForEventButton__aDAK6 {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--accent-text-color);
}

.EventCard-module_buttonOnFeaturedEventCard__DZb-K {
  left: 11.25rem;
  right: unset;
}

.EventCard-module_eventCardCancelledOverlay__Pt4MI {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--danger-color);
  font-weight: bold;
  font-size: 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  /* improves readability of text */
  text-shadow: 0 0 25px var(--base-color);
}

.EventCard-module_imageContainer__2LcVD {
  position: relative;
}

.EventCard-module_cancelled__M6ngS {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 0.33;
}

.EventDateAndTime-module_eventDate__T4oMJ {
  font-weight: bold;
  margin-left: 2px;
}

.EventDateAndTime-module_eventTime__83yI7 {
  margin-left: 1rem;
}

.EventDateAndTime-module_alignLeft__6r2QR {
  text-align: left;
}

.EventDateAndTime-module_calendarIcon__eIv-K {
  color: var(--primary-color);
  margin-right: 4px;
}

.ViewMoreButton-module_container__1bVql {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: grid;
  align-items: center;
}

.ViewMoreButton-module_divider__p6kwZ {
  width: 100%;
  border-bottom: 1px solid var(--default-border-color);
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.ViewMoreButton-module_button__jhYZC {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  justify-self: center;
}

.EventStatusBanner-module_eventStatusBanner__4OqaN {
  color: var(--primary-text-color);
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 1rem;
}

.EventStatusBanner-module_eventStatusBannerRegistered__uukKF {
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}

.EventStatusBanner-module_eventStatusBannerCancelled__cUsa6 {
  background-color: var(--danger-color);
  color: var(--danger-text-color);
}
.EventStatusBanner-module_eventStatusBannerFull__gnwu0 {
  background-color: var(--danger-color);
  color: var(--danger-text-color);
}
.EventStatusBanner-module_eventStatusBannerWaitlist__ti5og {
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}

.EventHead-module_imageContainer__GVP5O {
  margin-bottom: 1.5rem;
}

.RegistrantMeals-module_heading__2Emob {
  margin: 2rem 0;
}

.RegistrantMeals-module_mealRow__GoywA {
  margin-bottom: 1rem;
}

/* .form-check */
.RegistrantMeals-module_mealRow__GoywA > :first-child {
  display: flex;
  align-items: center;
}

/* .form-group */
.RegistrantMeals-module_mealRow__GoywA > :nth-child(2) > :first-child {
  margin-bottom: 0 !important;
}

.RegistrantMeals-module_dietaryRestrictions__UxJ0h {
  margin-top: 2rem;
}

.RegistrantMeals-module_hidden__eCyWA {
  visibility: hidden;
}

.FlagAgreeToTerms-module_noWrap__efH2M {
  white-space: nowrap;
}

.RegistrantGroups-module_heading__bQdwv {
  margin: 3rem 0 2rem 0;
}

.RegistrantWorkshops-module_heading__0kUsQ {
  margin: 3rem 0 2rem 0;
}

.RegistrantWorkshops-module_oddRowGroup__bqjQT {
  /* bootstrap style */
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.RegistrantWorkshops-module_evenRowGroup__R6VoN {
  background-color: var(--base-color) !important;
}

.RegistrantWorkshops-module_workshopName__xnC0q {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RegistrantWorkshops-module_orContainer__dyOYv {
  position: relative;
  width: 100%;
  height: 100%;
}

.RegistrantWorkshops-module_or__ohfx2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.RegistrantWorkshops-module_collapsedCell__uh-Jg {
  width: 1px;
  white-space: nowrap;
}

.EventRegistrationForm-module_form__75GSu input {
  scroll-margin-top: 3rem;
}

.EventRegistrationForm-module_narrowColumn__sEz6m {
  max-width: 50ch;
}

.EventRegistrationForm-module_section__7cWr3 {
  margin: 2rem 0;
}

.EventRegistrationForm-module_controls__sStbQ > * + * {
  margin-top: 2rem;
  margin-left: 1rem;
}

.EventRegistrationForm-module_flags__gDQDl > * {
  margin: 1rem 0;
}

.EventRegistrationThemeProvider-module_eventRegistrationSempra__9Wlp3 {
  --base-color: white;
  --base-text-color: #4b4b4b;
  --base-text-color-2: #8a8a8a;

  --default-color: #f8f8f8;
  --default-border-color: #dee2e6;
  --default-text-color: #4b4b4b;

  --primary-color: #0493d5;
  --primary-text-color: white;

  --highlight-color: #4accd4;
  --highlight--text-color: white;

  --accent-color: #d56701;
  --accent-text-color: white;

  --danger-color: #e0144b;
  --danger-text-color: white;

  --event-card-top-border: #dadada;
  --event-card-text-background: #efefef;

  font-size: 17px;
  background-color: var(--base-color);
  color: var(--base-text-color);
  font-family: Arial, Helvetica, sans-serif;
}

.EventRegistrationThemeProvider-module_eventRegistrationSdge__GAl4f {
  --base-color: white;
  --base-text-color: #666366;
  --base-text-color-2: #8a8a8a;

  --default-color: white;
  --default-border-color: #dcdcdc;
  --default-text-color: #666366;

  --primary-color: #1b457e;
  --primary-text-color: white;

  --highlight-color: #3076b1;
  --highlight--text-color: white;

  --accent-color: #d56701;
  --accent-text-color: white;

  --danger-color: #e0134e;
  --danger-text-color: white;

  --event-card-top-border: #dadada;
  --event-card-text-background: #efefef;

  font-size: 16px;
  background-color: var(--base-color);
  color: var(--base-text-color);
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--highlight-color);
}

.Header-module_header__QJZiF {
  background: white;
  margin-left: 1rem;
}

.SdgeFooter-module_footer__Szix3 {
  padding: 3rem 0;
  background: linear-gradient(to bottom, #2f75b0 0%, #315b97 100%);
  font-family: Arial, Helvetica, sans-serif;
}

.SdgeFooter-module_copyright__lTxiD {
  color: var(--base-color);
  text-align: center;
  font-size: 14px;
}

.SdgeFooter-module_footerCopyright__tctZg {
  padding-top: 10px;
}

.SdgeFooter-module_footerImage__N8wE6 {
  margin-top: 10px;
  width: 125px;
  height: auto;
}

.SdgeFooter-module_linksFormat__vdTrQ {
  text-align: center;
  margin-bottom: 1rem;
}

.SdgeFooter-module_link__2j3S6 {
  color: var(--base-color);
  font-size: 14px;
}
.SdgeFooter-module_link__2j3S6:hover {
  font-weight: bold;
  color: var(--base-color);
  text-decoration: none;
}
/* styling we can use for an unordered list <ul> if more more links are added*/
/* .footerList {
  padding-inline-start: 0;
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
} */

.SempraFooter-module_footer__99P5c {
  padding: 3rem 0;
  font-size: 12px;
  text-align: left;
  background-color: var(--default-color);
  border-top: solid 1px var(--event-card-top-border);
}

.SempraFooter-module_footerCopyright__yTU3Q {
  padding-top: 10px;
}

.SempraFooter-module_links__xkm5i {
  margin-left: 5px;
}

.Loading-module_container__Hy067 {
  margin: 4rem;
  display: flex;
  justify-content: center;
}

.Loading-module_container__Hy067 > * {
  width: 3rem !important;
  height: 3rem !important;
  background-color: var(--primary-color) !important;
}

.NotFound-module_message__kNTT5 {
  text-align: center;
  margin: 4rem 0;
}

.EventRegistrationPage-module_heading__-QYtw {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventRegistrationSuccessPage-module_heading__bnnCr {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventRegistrationSuccessPage-module_actionGrid__CwscH {
  margin: 4rem 0;
}

.RegistrationSuccessActionsGrid-module_parentContainer__WGiDN {
  background: var(--default-color);
  border-top: 1px solid var(--default-border-color);
  border-bottom: 1px solid var(--default-border-color);
  color: var(--default-text-color);
}

.RegistrationSuccessActionsGrid-module_informationRowLayout__paKK3 {
  height: 100%;
}

/* bootstrap xs screen width and above */
.RegistrationSuccessActionsGrid-module_informationRowLayout__paKK3 {
  padding: 0;
}
.RegistrationSuccessActionsGrid-module_informationColLayout__1p779 {
  padding: 3rem 0;
}
.RegistrationSuccessActionsGrid-module_informationColLayout__1p779:not(:last-child) {
  border-bottom: 1px solid var(--default-border-color);
}

/* bootstrap large screen width and above */
@media screen and (min-width: 992px) {
  .RegistrationSuccessActionsGrid-module_informationColLayout__1p779 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .RegistrationSuccessActionsGrid-module_informationColLayout__1p779:not(:last-child) {
    border-bottom: none;
    border-right: 1px solid var(--default-border-color);
  }
}

.EventAddToCalendar-module_description__JePfZ {
  margin-top: 2rem;
}

.EventJoinWaitlistSuccessPage-module_heading__Bm5D- {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventJoinWaitlistSuccessPage-module_actionButton__dV2Zq {
  margin-top: 2rem;
}

.EventCancelRegistrationPage-module_heading__usYw1 {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventCancelRegistrationPage-module_red__EAr3z {
  color: #ff0000;
  font-weight: bold;
}

.EventCancelRegistrationPage-module_buttons__sWll2 {
  margin-top: 2rem;
  display: flex;
}
.EventCancelRegistrationPage-module_buttons__sWll2 > * + * {
  margin-left: 1rem;
}

.EventCancelRegistrationSuccessPage-module_heading__NtEKf {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventDeclineInvitationPage-module_heading__aG4n0 {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventDeclineInvitationPage-module_red__ZiyeD {
  color: #ff0000;
  font-weight: bold;
}

.EventDeclineInvitationPage-module_buttons__F824r {
  margin-top: 2rem;
  display: flex;
}
.EventDeclineInvitationPage-module_buttons__F824r > * + * {
  margin-left: 1rem;
}

.EventDeclineInvitationSuccessPage-module_heading__aDRSe {
  color: #393939;
  margin: 1rem 0 2rem 0;
}

.EventReleaseWaiverPage-module_container__5b1TQ {
  margin-top: 2rem;
}
