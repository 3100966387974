.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid > * {
  margin-bottom: 0 !important;
}

.number {
  margin-top: 3.5rem;
}
