.formControls {
  display: inline-flex;
  align-items: center;
}
.formControls > .status,
.formControls > .divider,
.formControls > .actionButton {
  margin-right: 1rem !important;
}

.formControls > .button {
  margin: 0 !important;
}

.divider {
  /* copied from semantic UI - minus position: absolute */
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  width: 0;
  height: 2rem;
}
