.grid {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: baseline;
  column-gap: 2rem;
  row-gap: 0.5rem;
}

.hidden {
  visibility: hidden;
}

.option {
  margin-bottom: 0 !important;
}
