.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: -1em; /* matches Form.Field */
}

.eventStartAndEndTimeGrid {
  margin-bottom: 0 !important;
}
