.addButtonContainer {
  margin-top: 1rem;
}

.buttonLink {
  padding: 0;
  border: 0;
  background: none;
  color: var(--theme-primary) !important;
  cursor: pointer;
}

.buttonLink:active,
.buttonLink:focus {
  outline: none;
  color: var(--theme-secondary);
}

.fieldRow {
  display: flex;
}

.fieldRow > :first-child {
  flex: 1;
}

.fieldRow > * {
  margin-bottom: 0 !important;
}

.child {
  margin-left: 2rem;
}

/* only show remove button/row options on hover */

.removeButton {
  visibility: hidden;
  margin-top: 3rem !important;
}

.fieldRow:hover .removeButton,
.fieldRow:focus-within .removeButton {
  visibility: visible;
}
