.invited {
  background: var(--soft-grey) !important;
}

.registered {
  background: var(--soft-green) !important;
}

.waiting {
  background: var(--soft-yellow) !important;
}

.declined,
.cancelled,
.removed {
  background: var(--soft-red) !important;
}
