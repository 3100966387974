:root {
  --theme-base-raised: white;
  --theme-base-background-color: #e5e5e5;
  --theme-base-text-color: black;
  --theme-base-heading-color: #494949;
  --theme-base-border-color: #c5c5c5;
  --theme-primary: #0098da;
  --theme-primary-text-color: white;
  --theme-secondary: #012957;
  --theme-secondary-text-color: white;
  --theme-error-message-text-color: #9f3a38;
  --theme-error-message-border-color: #e0b4b4;
  --theme-error-message-background: white;

  --theme-opacity-down: 0.66;

  --soft-red: #eccece;
  --soft-yellow: #f1e0cf;
  --soft-grey: #e8e8e8;
  --soft-green: #d4efef;
}

body {
  background-color: var(--theme-base-background-color);
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:focus {
  color: var(--theme-primary);
}

a:active {
  color: var(--theme-secondary);
}

a:focus {
  outline: none;
}

.field.error {
  scroll-margin-top: calc(64px + 1em + 1px);
}

/* Semantic UI bugfix */
/* https://github.com/Semantic-Org/Semantic-UI-React/issues/1545 */
.blurring.dimmed.dimmable > :not(.dimmer).popup {
  filter: none;
}

/* Semantic UI overrides */

.ui.button.primary,
.ui.button.blue {
  background-color: var(--theme-primary);
  color: var(--theme-primary-text-color);
}

.ui.button.blue.inverted,
.ui.buttons.blue.inverted .button,
.ui.button.primary.inverted,
.ui.buttons.primary.inverted .button {
  background-color: transparent;
  box-shadow: 0 0 0 1px var(--theme-primary) inset !important;
  color: var(--theme-primary);
}

.ui.form .field > label {
  margin: 1rem 0 0.5rem 0;
}

/* form styles */
.ui.form .fields.disabled .field,
.ui.form .field :disabled,
.ui.form .field.disabled,
.ui.form .field.disabled > label {
  opacity: 1;
}
.ui.form .ui.checkbox input[disabled] ~ .box:after,
.ui.form .ui.checkbox input[disabled] ~ label,
.ui.form .ui.checkbox.disabled .box:after,
.ui.form .ui.checkbox.disabled label,
.ui.form .ui.input.disabled,
.ui.form .ui.search.disabled,
.ui.form .ui.dropdown.disabled,
.ui.form input[disabled] {
  opacity: 0.5;
}

/* readonly form styles */
.ui.form.readonly .ui.checkbox input[disabled] ~ .box:after,
.ui.form.readonly .ui.checkbox input[disabled] ~ label,
.ui.form.readonly .ui.checkbox.disabled .box:after,
.ui.form.readonly .ui.checkbox.disabled label,
.ui.form.readonly .fields.disabled .field,
.ui.form.readonly .field :disabled,
.ui.form.readonly .field.disabled,
.ui.form.readonly .field.disabled > label,
.ui.form.readonly .ui.input.disabled,
.ui.form.readonly .ui.search.disabled,
.ui.form.readonly .ui.dropdown.disabled,
.ui.form.readonly input[disabled] {
  opacity: 1;
}

.ui.input > * {
  width: 100%;
}

.ui.segment {
  background: var(--theme-base-raised);
  border: 1px solid var(--theme-base-border-color);
  box-sizing: border-box;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 2rem;
  margin-top: 0;
}

.ui.menu.vertical {
  /* app styles for containers */
  border: 1px solid var(--theme-base-border-color);
  box-sizing: border-box;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.ui.breadcrumb a,
.ui.breadcrumb .divider,
.ui.breadcrumb h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: var(--theme-base-heading-color);
}

.ui.breadcrumb .divider {
  margin: 0 0.5em;
}

.ui.top.right.pointing.dropdown > .menu {
  margin-top: 0.5rem;
}

/* Styles for the table and action menu on the Events page */
table.ui.selectable.single.line.striped.table.eventTable {
  cursor: pointer !important;
}

.ui.vertical.menu.eventTableActionMenu .item:before,
.ui.menu.eventTableActionMenu .item:before {
  background: none !important;
}

.ui.table {
  color: var(--theme-base-text-color);
}
.ui.table td.active,
.ui.table tr.active {
  background: #f2f2f2 !important;
}

/* event page sticky styles */
.ui.stuck-container [class^="PageHeader_header"] {
  background-color: var(--theme-secondary);
  color: var(--theme-secondary-text-color);
  transition: background-color 0.125s ease-out;
}
.ui.stuck-container [class^="PageHeader_header"] .ui.breadcrumb > *,
.ui.stuck-container [class^="PageHeader_header"] .ui.breadcrumb h1 {
  color: var(--theme-secondary-text-color);
}
.ui.stuck-container
  [class^="PageHeader_header"]
  [class^="EventControls_divider"] {
  border-left: 1px solid var(--theme-secondary-text-color);
  border-right: transparent;
  opacity: 0.5;
}
.ui.stuck-container [class^="PageHeader_header"] .ui.button.icon {
  color: var(--theme-secondary-text-color) !important;
  opacity: 1;
}

/* react-date-picker */
.react-datepicker-wrapper,
.react-datepicker-wrapper input {
  width: 100%;
}
.react-datepicker__time-container {
  min-width: 20ch;
}
.react-datepicker__time-container .react-datepicker__time {
  width: 100%;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
}

/* print styles */
.print {
  display: none;
}

@media print {
  @page {
    size: 8.5in 11in;
    margin: 0.5in 0.25in;
  }

  body {
    background-color: white;
  }

  .print {
    display: block;
  }

  body > #root > :not(.print) {
    display: none;
  }
}
