.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.link {
  background: none;
  padding: 0;
  text-decoration: none;
  border: 0;
  color: var(--theme-primary);
  cursor: pointer;
}

.link:hover {
  color: #1678c2; /* TODO theme */
}

.dependencyList {
  margin: 0;
  padding: 0;
}

.dependencyList > li {
  list-style: none;
}
