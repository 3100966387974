.grid {
  display: flex;
  max-width: min-content;
}

.grid > * + * {
  margin-left: 1rem !important;
  margin-bottom: 0 !important;
}

.grid + button {
  position: relative;
}

.grid + button > i {
  position: absolute;
  top: 0.5rem;
}

.name {
  min-width: 30ch;
  max-width: 40ch;
  margin-bottom: 0 !important;
}
